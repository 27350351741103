import { getCookie } from 'cookies-next';
import { common_blocks_cases } from './CommonBlockCase';
import TicketAttributeDetails from '../../../components/OrderDetails/Ticket/TicketAttributeDetails';
import TicketQRCOde from '../../../components/OrderDetails/Ticket/TicketQRCOde';

//  component_decide
export const ticket_ui_component_decide = ({
  order_number,
  block,
  block_data_key,
  fetch_by,
  data,
  isStatic,
  extra_data,
  styles,
  dynamic_page_sections,
  screen_width: width,
  isEnabled = true,
  cart_attributes,
  order_details,
}) => {
  const _styles = styles ?? data?.styles ?? {};
  const section = {
    order_number,
    block,
    block_data_key,
    fetch_by,
    data,
    isStatic,
    extra_data,
    styles,
    isEnabled,
  };

  switch (block) {
    // case 'category':
    //   return (
    //     <Categories6
    //       categories={
    //         dynamic_page_sections
    //           ? dynamic_page_sections.filter(
    //               (item) => item.order_number === order_number
    //             )[0]?.data
    //           : null
    //       }
    //       extra_data={extra_data}
    //       styles={_styles}
    //       key={order_number}
    //     />
    //   );
    case 'cart_attribute':
      let selected_attr = data?.attribute_id
        ? cart_attributes?.filter((it) => it.id == data?.attribute_id)[0]
        : null;
      return (
        isEnabled &&
        cart_attributes &&
        cart_attributes.length > 0 &&
        selected_attr && (
          <TicketAttributeDetails
            attributes={cart_attributes}
            data={data}
            styles={styles}
            attr={selected_attr}
            key={order_number}
          />
        )
      );
    case 'qr_code':
      return isEnabled && <TicketQRCOde order_details={order_details} />;

    default:
      return common_blocks_cases({ section });
  }
};
