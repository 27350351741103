import React from 'react';
import QRCode from 'react-qr-code';

const TicketQRCOde = ({ order_details }) => {
  return (
    <QRCode
      size={100}
      style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
      value={order_details?.reference_number}
      viewBox={`0 0 256 256`}
    />
  );
};

export default TicketQRCOde;
